import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Article from "../components/article";

export const query = graphql`
  query($slug: String!, $language: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      publishedDate(formatString: "Do MMMM, YYYY")
      featuredImage {
        fluid(maxWidth: 864) {
          ...GatsbyContentfulFluid
        }
      }
      author {
        name
      }
      content {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            fluid(maxWidth: 864) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ArticleTemplate = (props) => {
  return (
    <Layout menuColor={"black"}>
      <SEO title={props.data.contentfulArticle.title} />
      <Article article={props.data.contentfulArticle} />
    </Layout>
  );
};

export default ArticleTemplate;
