import { FluidObject } from "gatsby-image";
import React, { FunctionComponent } from "react";
import { BLOCKS } from "@contentful/rich-text-types";
import Img from "gatsby-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import * as styles from "./article.module.scss";

interface ArticleProp {
  title: string;
  publishedDate: string;
  author: {
    name: string;
  };
  featuredImage: {
    fluid: FluidObject;
  };
  content: any;
}

interface Props {
  article: ArticleProp;
}

const Article: FunctionComponent<Props> = ({ article }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_2]: (_node, children) => <h4>{children}</h4>,
      [BLOCKS.HEADING_3]: (_node, children) => <h5>{children}</h5>,
      [BLOCKS.HEADING_4]: (_node, children) => <h6>{children}</h6>,
      [BLOCKS.HEADING_5]: (_node, children) => <h6>{children}</h6>,
      [BLOCKS.HEADING_6]: (_node, children) => <h6>{children}</h6>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <div className={styles.imageWrapper}>
          <Img {...node.data.target} />
        </div>
      ),
    },
  };

  return (
    <article>
      <div className={styles.heading}>
        <h2 className={styles.title}>{article.title}</h2>
        <span className={styles.published}>
          {article.author ? `By ${article.author.name}` : "Posted"} on{" "}
          {article.publishedDate}
        </span>
      </div>

      {article.featuredImage && (
        <Img
          className="featured"
          fluid={article.featuredImage.fluid}
          alt={article.title}
        />
      )}
      <div className={styles.content}>
        {renderRichText(article.content, options)}
      </div>
    </article>
  );
};

export default Article;
